import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/Seo';
import { Container, Card } from 'reactstrap';
import Layout from '../components/layouts/Layout';

const Terms = ({ data }) => {
  const terms = data.allStrapiTerms?.nodes?.[0];

  return (
    <Layout navColor="white" margined={true}>
      <SEO title={`${terms?.title}`} description={terms?.title} />
      <Container fluid="md" className="py-5">
        <h1 className="mb-4">{terms.title}</h1>
        <Card className="p-4">
          {terms.sections.map((section, i) => (
            <Fragment key={`term-section-${i}`}>
              <h3 className="mb-4">{section.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: section.description }} />
            </Fragment>
          ))}
        </Card>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query Terms($id: Int, $type: String) {
    allStrapiTerms(filter: { strapiId: { eq: $id }, nodeType: { eq: $type } }) {
      nodes {
        title
        nodeType
        strapiId
        sections {
          title
          description
        }
      }
    }
  }
`;

export default Terms;
